export const currenciesMap = {
  ILS: [undefined, 'Israeli Shekel', '₪'],
  USD: ['en-US', 'US Dollar', '$'],
  GBP: ['en-GB', 'British Pound', '£'],
  EUR: ['de-DE', 'Euro', '€'],
  JPY: ['ja-JP', 'Japanese Yen', '¥'],
  CAD: ['en-CA', 'Canadian Dollar', 'CA$'],
  PLN: ['pl-PL', 'Polish Zloty', 'zł'],
  HUF: ['hu-HU', 'Hungarian Forint', 'Ft'],
} as const;
