import {
  CheckIcon,
  Combobox,
  type ComboboxItem,
  type ComboboxStore,
  Group,
  Highlight,
  Stack,
} from '@mantine/core';
import { highlightStyles } from '~/Components/Form/shared/Highlight';

type GlobalSearchItem = ComboboxItem & {
  cardTypeId: string;
  id: string;
  match: {
    email: string | null;
    phone: string | null;
  };
};

export const HighlightedOption: React.FC<{
  option: GlobalSearchItem;
  query: string;
  combobox: ComboboxStore;
  selected?: boolean;
}> = ({ option, query, combobox, selected }) => {
  return (
    <Combobox.Option
      value={option.id}
      key={option.id}
      active={true}
      onMouseOver={() => combobox.resetSelectedOption()}
    >
      <Group gap="sm">
        {selected ? <CheckIcon size={12} /> : null}
        <Stack gap={0}>
          <Highlight
            highlightStyles={highlightStyles}
            color={'violet.3'}
            fw={500}
            highlight={query}
            size="sm"
          >
            {option.label}
          </Highlight>
          {option.match.email && (
            <Highlight highlightStyles={highlightStyles} fw={400} highlight={query} size="xs">
              {option.match.email}
            </Highlight>
          )}
          {option.match.phone && (
            <Highlight highlightStyles={highlightStyles} fw={400} highlight={query} size="xs">
              {option.match.phone}
            </Highlight>
          )}
        </Stack>
      </Group>
    </Combobox.Option>
  );
};
