export enum TestIds {
  // Components
  Modal = 'modal',
  Loader = 'loader',
  SuperDateRangePicker = 'super-date-range-picker',
  FloatingEditButton = 'floating-edit-button',
  MultiFilterRemove = 'multi-filter-remove',
  MultiFilterContainer = 'multi-filter-container',
  MultiFilterClearAll = 'multi-filter-clear-all',

  // Navigation
  NewRecord = 'new-record-button',
  CreateNewRecord = 'create-new-record-button',

  // Settings
  ApiToken = 'api-token',
  LanguageSelect = 'language-select',
  CreateRole = 'create-role',
  DeleteRole = 'delete-role',
  Roles = 'roles',
  Permissions = 'permissions',
  UserRoles = 'userRoles',
  Organizations = 'organizations',
  UpdateAccountSettings = 'update-account-settings',
  CardTypeSelector = 'card-type-selector',
  RichTextEditor = 'rich-text-editor',

  // Cards table
  TotalCards = 'total-cards',
  TableColumnPicker = 'table-column-picker',
  TableSearchInput = 'table-search-input',
  ClearTableSearchInput = 'clear-table-search-input',
  TableToggleRowSelection = 'table-toggle-row-selection',
  TableDeleteSelectedRows = 'table-delete-selected-rows',

  // Card
  NewActivity = 'new-activity',
  CardBreadcrumbName = 'card-breadcrumb-name',
  CardBreadcrumbPrevious = 'card-breadcrumb-previous',
  CardBreadcrumbNext = 'card-breadcrumb-next',

  // Finance documents
  NewFinanceDocument = 'new-finance-document',

  // Agenda
  NewMeeting = 'new-meeting',
}

type TestId = `${TestIds}`;

const selector = (testId: TestId): string => {
  return `[data-test-id="${testId}"]`;
};

const prop = (testId: TestId) => {
  return { 'data-test-id': testId };
};

export const testIdUtils = { selector, prop };
