import { DEFAULT_THEME, type DefaultMantineColor } from '@mantine/core';

const MANTINE_COLORS = [
  'gray',
  'red',
  'pink',
  'grape',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'green',
  'lime',
  'yellow',
  'orange',
  'teal',
] as const;

type MapColors = Record<DefaultMantineColor, string>;

const mapColorToHex = MANTINE_COLORS.reduce<MapColors>((acc, c) => {
  const colors = DEFAULT_THEME.colors[c];
  if (!colors) return acc;
  acc[c] = colors[5];
  return acc;
}, {} as MapColors);

export const appColors = {
  primary: '#7950f2',
  primaryDark: '#6741d9',
  toHex: mapColorToHex,
  mainColors: MANTINE_COLORS,
  themeColor: 'violet',
} as const;
