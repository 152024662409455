import { Avatar } from '@mantine/core';
import { type DatePickerProps } from '@mantine/dates';

const dayRenderer: DatePickerProps['renderDay'] = (date) => {
  const isToday = date.toDateString() === new Date().toDateString();
  return isToday ? <Avatar color="green">{date.getDate()}</Avatar> : date.getDate();
};

export const dateComponentUtils = {
  dayRenderer,
};
