import {
  ActionIcon,
  type ActionIconProps,
  type ElementProps,
  Tooltip,
  type TooltipProps,
} from '@mantine/core';
import { forwardRef } from 'react';
import { type TablerIconType } from './types';

export interface AppActionIconProps
  extends ActionIconProps,
    ElementProps<'button', keyof ActionIconProps> {
  Icon: TablerIconType;
  tooltip: string;
  position?: TooltipProps['position'];
  openDelay?: TooltipProps['openDelay'];
}

export const AppActionIcon = forwardRef<HTMLButtonElement, AppActionIconProps>(
  ({ tooltip, position = 'top', Icon, color, openDelay = 200, ...props }, ref) => {
    return (
      <Tooltip label={tooltip} position={position} openDelay={openDelay}>
        <ActionIcon color={color} variant="subtle" {...props} ref={ref}>
          <Icon height={'70%'} width={'70%'} />
        </ActionIcon>
      </Tooltip>
    );
  },
);

AppActionIcon.displayName = 'AppActionIcon';
