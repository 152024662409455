import { type FilterField } from '~/Components/Form/MultiFilters/MultiFilters';
import { searchSingleFilterSchema } from '~/server/api/schema/search.schema';
import { type IFilter, type IFilterType, type ISingleFilter } from '~/server/services/types';
import { type FieldType } from '~db/types';

const mapFieldToFilter: Partial<Record<FieldType, IFilterType>> = {
  CHECKBOX: 'BOOLEAN',
  HIDDEN: 'BOOLEAN',
  RADIO: 'BOOLEAN',
  DATE: 'DATE',
  TIME: 'DATE',
  CURRENCY: 'LIST',
  MULTI_SELECT: 'LIST',
  SINGLE_SELECT: 'LIST',
  LOOKUP_CARD: 'LIST',
  LOOKUP_USER: 'LIST',
  LOOKUP_ACTIVITY: 'LIST',
  NUMBER: 'NUMBER',
  RANGE: 'NUMBER',
  ACCOUNTING: 'NUMBER',
  AGE: 'AGE',
};

const defaultOperator: Record<IFilterType, ISingleFilter['operator']> = {
  TEXT: 'CONTAINS',
  DATE: 'BETWEEN',
  NUMBER: 'BETWEEN',
  BOOLEAN: 'EQUAL',
  AGE: 'BETWEEN',
  LIST: 'IN',
  ID: 'EQUAL',
};

const fieldTypeToFilter = (fieldType: FieldType): IFilterType => {
  return mapFieldToFilter[fieldType] ?? 'TEXT';
};

const parse = <Model extends Record<string, unknown>>(
  filters: IFilter<Model>,
  fields: FilterField[],
): IFilter<Model> => {
  return Object.entries(filters).reduce((acc, [fieldSystem, filter]) => {
    const field = fields.find((field) => field.system === fieldSystem);
    if (!field || !filter) return acc;
    const { success, data: parsedFilter } = searchSingleFilterSchema.safeParse(filter);
    if (!success) return acc;
    parsedFilter.operator = parsedFilter.operator ?? defaultOperator[filter.type];
    return { ...acc, [fieldSystem]: parsedFilter };
  }, {});
};

export const filtersUtils = {
  fieldTypeToFilter,
  defaultOperator,
  parse,
};
