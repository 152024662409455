import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { windowService } from '~/core/window';
import { useLocale } from './locale.hook';

export const useSpeechRecognition = (onChange?: (value: string) => void) => {
  const [result, setResult] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const recognition = useRef<SpeechRecognition | null>(null);
  const { lang } = useLocale();

  const compatibility = useMemo(() => {
    if (!windowService.isClient) return false;
    return !!window.webkitSpeechRecognition || !!window.SpeechRecognition;
  }, []);

  useEffect(() => {
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    recognition.current = new SpeechRecognition();

    recognition.current.lang = lang;
    recognition.current.interimResults = true;
    recognition.current.continuous = true;
    recognition.current.interimResults = true;
    recognition.current.onresult = (event) => {
      const currentResult = event.results[event.resultIndex];
      if (currentResult?.[0]) {
        const transcript = currentResult[0].transcript;
        setResult(transcript);
        onChange?.(transcript);
      }
    };
  }, [lang, onChange]);

  const startListening = useCallback(() => {
    if (recognition.current) {
      recognition.current.start();
      setIsListening(true);
    }
  }, []);

  const stopListening = useCallback(() => {
    if (recognition.current) {
      recognition.current.stop();
      setIsListening(false);
    }
  }, []);

  return {
    compatibility,
    startListening,
    result,
    isListening,
    stopListening,
  };
};
