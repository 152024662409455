import { NumberInput, type NumberInputProps } from '@mantine/core';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { zodUtils } from '~/utils/zod-utils';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

type FormNumberInputProps = IBaseFormInputProps & NumberInputProps;

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  name,
  label,
  description,
  required = false,
  hideError = false,
  onChange: handleChange,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);

  return (
    <NumberInput
      id={name}
      value={(value as number) || ''}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      styles={{ label: { width: '100%' } }}
      error={hideError ? Boolean(errorMessage) : errorMessage}
      hideControls
      onChange={(value: string | number) => {
        const result = zodUtils.numberSchema.safeParse(value);
        if (result.success) {
          onChange(result.data);
          handleChange?.(result.data);
        } else {
          console.error('Error while parsing number', result.error);
        }
      }}
      {...rest}
      {...props}
    />
  );
};
