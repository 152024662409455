import { type DefaultMantineColor, Tooltip } from '@mantine/core';
import { type IconProps } from '@tabler/icons-react';
import { useMantineColor } from '../../../ui/hooks/mantine-color.hook';
import { type TablerIconType } from './types';

type AppIconProps = IconProps & {
  Icon: TablerIconType;
  color?: DefaultMantineColor;
  tooltip?: string;
};

export const AppIcon: React.FC<AppIconProps> = ({
  Icon,
  color,
  tooltip,
  stroke = 1,
  size = '1.4rem',
  ...props
}) => {
  const { color: mantineColor } = useMantineColor(color);
  if (tooltip) {
    return (
      <Tooltip label={tooltip} position="bottom">
        <span>
          <Icon color={mantineColor} stroke={stroke} size={size} {...props} />
        </span>
      </Tooltip>
    );
  }
  return <Icon color={mantineColor} stroke={stroke} size={size} {...props} />;
};
