import { Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { keepPreviousData } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type PlainCardType } from '~/server/services/card-type/types';
import { type Card } from '~db/types';
import { api } from '../../trpc';
import { AppLink } from '../Shared/AppLink';

export const useCardsByIds = (ids: string[] = []) => {
  const { data: cards, isLoading: isLoadingCards } = api.cards.getByIds.useQuery(
    { ids },
    {
      enabled: Boolean(ids.length),
      staleTime: 2 * 60 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
      placeholderData: keepPreviousData,
    },
  );

  return { cards, isLoadingCards };
};

export const useCard = (id?: string | null, enabled = true) => {
  const {
    data: card,
    isLoading: isLoadingCard,
    error: cardError,
    refetch,
  } = api.cards.get.useQuery(
    { id: id! },
    {
      enabled: Boolean(id) && enabled,
      staleTime: 2 * 60 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
    },
  );
  return {
    refetch,
    card,
    isLoadingCard,
    cardError,
  };
};

export const useCreateCard = ({
  cardType,
  onCreation,
}: {
  cardType?: PlainCardType | null;
  onCreation?: (card: Card) => void;
}) => {
  const { t: tCard } = useTranslation('cardComponent');
  const utils = api.useUtils();
  const { mutateAsync: createCard, isPending: isCreatingCard } = api.cards.create.useMutation({
    onSuccess: async (card) => {
      if (!cardType) return;
      notifications.show({
        title: tCard('Card created'),
        autoClose: 10_000,
        message: (
          <Group>
            {tCard('Card {{name}} created', { name: card.name })}
            {!cardType.settings?.isHidden ? (
              <AppLink href={`/cards/${cardType.slug}/${card.id}`}>
                {tCard('Go to {{cardType}}', { cardType: cardType.name })}
              </AppLink>
            ) : null}
          </Group>
        ),
      });

      if (cardType.id) {
        await utils.cards.search.invalidate({ cardTypeId: cardType.id });
      }
      onCreation?.(card);
    },
  });

  return { isCreatingCard, createCard };
};

export const useDuplicateCard = ({ cardType, card }: { cardType: PlainCardType; card: Card }) => {
  const { createCard } = useCreateCard({ cardType });
  const { t } = useTranslation('cardComponent');
  const duplicateCard = useCallback(async () => {
    await createCard({
      ...card,
      name: t('{{name}} - copy', { name: card.name }),
      id: undefined,
    });
  }, []);

  return { duplicateCard };
};

export const useDeleteCard = ({ cardType, card }: { cardType: PlainCardType; card: Card }) => {
  const { t } = useTranslation('cardPage');
  const router = useRouter();
  const utils = api.useUtils();
  const { mutate: delCard, isPending } = api.cards.delete.useMutation({
    async onSuccess() {
      notifications.show({
        title: t('Card deleted'),
        message: t("{{cardTypeName}} '{{cardName}}' deleted successfully", {
          cardTypeName: cardType.name,
          cardName: card.name,
        }),
        color: 'green',
      });
      await utils.cards.search.invalidate({ cardTypeId: cardType.id });
      router.push(`/cards/${cardType.slug}`);
    },
  });

  const deleteCard = useCallback(() => {
    modals.openConfirmModal({
      title: t('Delete card'),
      children: t('Are you sure you want to delete {{cardTypeName}} "{{cardName}}"?', {
        cardName: card.name,
        cardTypeName: cardType.name,
      }),
      labels: {
        cancel: t('Cancel'),
        confirm: t('Delete'),
      },
      onConfirm() {
        delCard({ id: card.id });
      },
    });
  }, [card.id, delCard]);

  return { deleteCard, isPending };
};
