import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type RelativeTime, dateTimeService } from '~/utils/date-time.service';
import { type SuperDateRangeValue } from './types';

export const useQuickSelectionOptions = () => {
  const { t } = useTranslation('dateComponent');
  return useMemo<{ label: string; value: RelativeTime }[]>(
    () => [
      { label: t('Today'), value: 'today' },
      { label: t('Last 7 days'), value: 'last-7-days' },
      { label: t('Yesterday'), value: 'yesterday' },
      { label: t('This week'), value: 'this-week' },
      { label: t('Last week'), value: 'last-week' },
      { label: t('Last 30 days'), value: 'last-30-days' },
      { label: t('This month'), value: 'this-month' },
      { label: t('Last month'), value: 'last-month' },
      { label: t('This year'), value: 'this-year' },
      { label: t('Last year'), value: 'last-year' },
      { label: t('Until now'), value: 'until-now' },
      { label: t('From today'), value: 'from-today' },
    ],
    [t],
  );
};

export const useDatePickerDisplayDate = (value?: SuperDateRangeValue) => {
  const quickSelectOptions = useQuickSelectionOptions();
  const { t } = useTranslation('dateComponent');

  const displayDate = useMemo(() => {
    const [from, to] = value ?? [];
    const option = quickSelectOptions.find((option) => option.value === from);
    if (option) return option.label;
    if (from && to) return `${dateTimeService.tryFormat(from)} - ${dateTimeService.tryFormat(to)}`;
    if (from) return `${t('From')} ${dateTimeService.tryFormat(from)}`;
    if (to) return `${t('To')} ${dateTimeService.tryFormat(to)}`;

    return t('Choose dates');
  }, [value, quickSelectOptions, t]);

  return { displayDate };
};
