import dayjs from 'dayjs';
import { type RelativeTime, dateTimeService } from '~/utils/date-time.service';
import { type SuperDateRangeInitialValue, type SuperDateRangeValue } from './types';

const supportedRelativeTimes = Object.keys(dateTimeService.mapRelativeToValue);

const tryParseInitialValue = (initialValue: SuperDateRangeInitialValue): SuperDateRangeValue => {
  if (!initialValue) return null;
  if (!Array.isArray(initialValue)) return null;
  const [from, to] = initialValue;
  if (
    (typeof from === 'string' && typeof to === 'string') ||
    (dateTimeService.isValidDate(from!) && dateTimeService.isValidDate(to!))
  ) {
    const parsedFrom = dayjs(from);
    const parsedTo = dayjs(to);
    if (parsedFrom.isValid() && parsedTo.isValid()) {
      return [parsedFrom.toDate(), parsedTo.toDate()];
    }
  }
  if (typeof from === 'string' && supportedRelativeTimes.includes(from)) {
    return [from as RelativeTime];
  }
  return null;
};

export const superDateRangePickerUtils = {
  tryParseInitialValue,
};
