import { Text, Textarea, type TextareaProps, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconMicrophone, IconMicrophoneFilled } from '@tabler/icons-react';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpeechRecognition } from '~/ui/hooks/speech-recognition.hook';
import { AppActionIcon } from '../Shared/Icon/AppActionIcon';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

export type FormTextareaProps = TextareaProps & IBaseFormInputProps & { countChars?: number };

const Counter: React.FC<{ value: string; countChars: number }> = ({ value, countChars }) => {
  const color = value.length > countChars ? 'orange' : 'grey';
  const { t } = useTranslation('fieldsComponent');
  return (
    <Tooltip label={t('Max number of characters')} withArrow position="bottom-start">
      <Text c={color} size="xs">
        {value.length}/{countChars}
      </Text>
    </Tooltip>
  );
};

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  label,
  description,
  required = false,
  hideError = false,
  countChars,
  ...props
}) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation('fieldsComponent');
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const errorMessage = parseFormErrorMessage(error?.message);
  const handleRecognitionChange = useCallback(
    (value: string) => {
      setValue(name, value);
    },
    [setValue, name],
  );
  const { startListening, isListening, stopListening, compatibility } =
    useSpeechRecognition(handleRecognitionChange);
  const { ref: hoverRef, hovered } = useHover();
  const showSpeechRecognition = compatibility && (hovered || isListening);
  return (
    <Textarea
      id={name}
      wrapperProps={{ ref: hoverRef }}
      value={(value as string) || ''}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      styles={{ label: { width: '100%' } }}
      error={hideError ? Boolean(errorMessage) : errorMessage}
      description={countChars ? <Counter value={value as string} countChars={countChars} /> : null}
      inputWrapperOrder={['label', 'input', 'description', 'error']}
      rightSection={
        showSpeechRecognition ? (
          <AppActionIcon
            tooltip={isListening ? t('Stop Speech Recognition') : t('Start Speech Recognition')}
            Icon={isListening ? IconMicrophoneFilled : IconMicrophone}
            color={isListening ? 'red' : 'gray'}
            onClick={isListening ? stopListening : startListening}
          />
        ) : undefined
      }
      {...rest}
      {...props}
    />
  );
};
