import { useAuth } from '@clerk/nextjs';
import { useDirection } from '@mantine/core';
import { getCookie, setCookie } from 'cookies-next';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeService } from '~/utils/date-time.service';
import { LocaleSettings, languageUtils } from '~/utils/language';
import { type AccountSettingsTable, type Language } from '~db/types';

export const useLocale = (): LocaleSettings => {
  const { i18n } = useTranslation();
  const locale = i18n.language as Language;
  return languageUtils.getLocaleSettings(locale);
};

export const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'he', label: 'עברית' },
  { value: 'hu', label: 'Magyar' },
];

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const { setDirection } = useDirection();

  const change = useCallback((language: Language) => {
    void i18n.changeLanguage(language);

    const localeSettings = languageUtils.getLocaleSettings(language);
    setDirection(localeSettings.dir);
    dayjs.locale(language);
    setCookie(languageUtils.languageCookieKey, language);
    document.documentElement.dir = localeSettings.dir;
    dateTimeService.setLocale(localeSettings);
  }, []);

  return { change };
};

export const useLanguageInit = (settings?: AccountSettingsTable['settings']) => {
  const { isSignedIn } = useAuth();
  const { change } = useChangeLanguage();

  useEffect(() => {
    const cookie = getCookie(languageUtils.languageCookieKey);
    const language = languageUtils.getDefault({ settings, cookie });
    change(language);
  }, [settings?.language, isSignedIn]);
};
