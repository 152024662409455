import { Controller, useController, useFormContext } from 'react-hook-form';
import {
  GlobalSearchComponent,
  type GlobalSearchProps,
} from '~/Components/Navigation/Header/GlobalSearch/Component';

type FormGlobalCardSelectorProps = GlobalSearchProps & { name: string };

export const FormCardsSelector: React.FC<FormGlobalCardSelectorProps> = ({
  name,
  onSubmit,
  ...props
}) => {
  const { control } = useFormContext<Record<string, string[]>>();
  const {
    field: { value, onChange, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <GlobalSearchComponent
      {...props}
      {...rest}
      value={value}
      radius={'sm'}
      onRemove={(id) => {
        onChange(value.filter((v) => v !== id));
      }}
      onSubmit={(item) => {
        if (!item) {
          onChange([]);
          onSubmit?.(item);
        } else {
          onChange([...(value ?? []), item.card.id]);
          onSubmit?.(item);
        }
      }}
    />
  );
};
export const FormCardSelector: React.FC<FormGlobalCardSelectorProps> = ({
  name,
  onSubmit,
  ...props
}) => {
  const { control } = useFormContext<Record<string, string>>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...rest } }) => {
        return (
          <GlobalSearchComponent
            {...props}
            {...rest}
            radius={'sm'}
            value={value ? [value] : []}
            onRemove={() => {
              onChange(null);
            }}
            onSubmit={(item) => {
              if (!item) {
                onChange(null);
                onSubmit?.(item);
              } else {
                onChange(item.card.id);
                onSubmit?.(item);
              }
            }}
          />
        );
      }}
    />
  );
};
