import { isArray, isDate, isNullOrUndefined, isObject, isUndefined } from 'is-what';
import { __utilsSet } from './set';

const removeNulls = <T extends Record<string, unknown> | null | undefined>(obj: T): T => {
  if (!obj) return obj;
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value != null)
      .map(([key, value]) => [
        key,
        isDate(value) ? value : isObject(value) ? removeNulls(value) : value,
      ]),
  ) as T;
};

const groupBy = <T extends Record<string, unknown>>(arr: T[], key: keyof T): Record<string, T[]> => {
  return arr.reduce<Record<string, T[]>>((acc, item) => {
    const group = item[key] as string;
    return {
      ...acc,
      [group]: [...(acc[group] ?? []), item],
    };
  }, {});
};

const objectDeepEqual = (obj1?: any, obj2?: any): boolean => {
  // Base case: If both objects are identical, return true.
  if (obj1 === obj2) return true;

  // Check if both objects are objects and not null.
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null)
    return false;

  if (isArray(obj1) !== isArray(obj2)) return false;

  if (isArray(obj1) && isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!objectDeepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
  }

  // Get the keys of both objects.
  // Check if the number of keys is the same.
  // Iterate through the keys and compare their values recursively.
  if (isObject(obj1) && isObject(obj2)) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;

      const val1 = obj1[key];
      const val2 = obj2[key];
      if (!objectDeepEqual(val1, val2)) {
        return false;
      }
    }
  }
  // If all checks pass, the objects are deep equal.
  return true;
};

const isNil = (value: unknown): boolean => value === null || value === undefined;
const isDefined = (value: unknown): boolean => !isNil(value);

const get = <T>(object: T, path?: string, defaultValue?: unknown): unknown => {
  if (!path || !isObject(object)) {
    return defaultValue;
  }

  const result = path.split(/[,[\].]+?/).reduce<unknown>((result, key) => {
    if (isNullOrUndefined(result)) return result;
    if (isArray(result)) return result[+key];
    if (isObject(result)) return result[key];
  }, object);

  return isUndefined(result) || result === object
    ? isUndefined(object[path as keyof T])
      ? defaultValue
      : object[path as keyof T]
    : result;
};

const stripHtml = (html?: string): string => {
  if (!html) return '';
  const replaced = html.replace(/<\/p>/g, '\n');
  // remove all html tags
  return replaced.replace(/<[^>]*>?/gm, '').trim();
};

const objectDiffCompare = <T extends Record<string, unknown>, M extends Record<string, unknown>>(
  obj1: T,
  obj2: M,
): { key: string; from: any; to: any }[] => {
  return Object.entries(obj2).reduce<{ key: string; from: any; to: any }[]>((acc, [key, val2]) => {
    const val1 = obj1[key];

    // If values are objects, compare deeply
    if (isObject(val1) && isObject(val2)) {
      const nestedDiff = objectDiffCompare(val1, val2);
      if (nestedDiff.length > 0) {
        acc.push(...nestedDiff); // Push the innermost differences, not the parent key
      }
      return acc; // Return early to avoid further processing
    }
    // If they are arrays, compare deeply
    if (isArray(val1) && isArray(val2)) {
      if (!objectDeepEqual(val1, val2)) {
        acc.push({ key, from: val1, to: val2 });
      }
      return acc; // Return early to avoid further processing
    }
    // If the values are different, record the innermost difference
    if (val1 !== val2) {
      acc.push({ key, from: val1, to: val2 });
      return acc; // Return early to avoid further processing
    }

    return acc;
  }, []);
};

const randomString = (length = 10): string => {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

const entries = <T extends Record<string, unknown>>(obj: T): [keyof T, T[keyof T]][] =>
  Object.entries(obj) as [keyof T, T[keyof T]][];

export const utils = {
  objectDiffCompare,
  removeNulls,
  groupBy,
  isNil,
  randomString,
  isDefined,
  stripHtml,
  objectDeepEqual,
  set: __utilsSet,
  get,
  entries,
};
