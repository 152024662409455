import {
  Button,
  Combobox,
  type ComboboxProps,
  Flex,
  Loader,
  type MantineRadius,
  Menu,
  Pill,
  PillsInput,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCards, IconChevronDown, IconSearch } from '@tabler/icons-react';
import { keepPreviousData } from '@tanstack/react-query';
import React, { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardsByIds } from '~/Components/Card/card.hook';
import { useLocale } from '~/ui/hooks/locale.hook';
import { utils } from '~/utils/basic';
import { testIdUtils } from '~/utils/test-id.service';
import { type Card, type CardType } from '../../../../../db/types';
import { api } from '../../../../trpc';
import { HighlightedOption } from './HighlightedOption';

export type IGlobalSearchSubmit = (args: { card: Card; cardType: CardType }) => void;

export type GlobalSearchProps = ComboboxProps & {
  cardTypes: CardType[];
  onSubmit?: IGlobalSearchSubmit;
  debounce?: number;
  name?: string;
  radius?: MantineRadius;
  description?: string;
  label?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  onRemove?: (value: string) => void;
  initialCardType?: CardType;
};

export const GlobalSearchComponent: React.FC<GlobalSearchProps> = forwardRef<
  HTMLInputElement,
  GlobalSearchProps
>(
  (
    {
      cardTypes,
      onSubmit,
      debounce = 200,
      name = 'globalSearch',
      radius = 'xl',
      description,
      label,
      value,
      onRemove,
      initialCardType,
      withinPortal = false,
      disabled = false,
    },
    ref,
  ) => {
    const { t } = useTranslation('headerComponent');
    const [query, setQuery] = useState('');
    const [debouncedValue] = useDebouncedValue(query, debounce);
    const { align } = useLocale();
    const [selectedCardType, setSelectedCardType] = useState<CardType | undefined>(
      initialCardType ? initialCardType : cardTypes.length === 1 ? cardTypes[0] : undefined,
    );
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const enabled = debouncedValue !== '' || combobox.dropdownOpened;
    const { data: cards, isFetching } = api.cards.freeSearch.useQuery(
      {
        query: debouncedValue,
        cardTypeIds: selectedCardType?.id ? [selectedCardType?.id] : cardTypes.map((item) => item.id),
      },
      {
        gcTime: 10 * 60 * 1000,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
        enabled,
      },
    );

    const { cards: selectedCards, isLoadingCards } = useCardsByIds(value);

    const values = selectedCards?.map((card) => (
      <Pill key={card.id} withRemoveButton onRemove={() => onRemove?.(card.id)} disabled={disabled}>
        {card.name}
      </Pill>
    ));

    const groupedOptions = useMemo(() => {
      if (!cards || !cardTypes) return {};

      return utils.groupBy(
        cards.map((item) => {
          return {
            cardTypeId: item.cardTypeId,
            id: item.id,
            value: item.id,
            label: item.name,
            match: {
              email: item.email?.toLowerCase().includes(query.toLowerCase()) ? item.email : null,
              phone: item.phone?.toLowerCase().includes(query.toLowerCase()) ? item.phone : null,
            },
          };
        }),
        'cardTypeId',
      );
    }, [cards, cardTypes, query]);

    const groupedItems = useMemo(
      () =>
        Object.keys(groupedOptions).length > 1
          ? Object.entries(groupedOptions)?.map(([cardTypeId, options]) => {
              if (!options) return null;
              return (
                <Combobox.Group
                  key={cardTypeId}
                  label={cardTypes.find((type) => type.id === cardTypeId)?.pluralName}
                >
                  {options.map((option) => (
                    <HighlightedOption
                      key={option.id}
                      option={option}
                      query={debouncedValue}
                      combobox={combobox}
                      selected={value?.includes(option.id)}
                    />
                  ))}
                </Combobox.Group>
              );
            })
          : Object.values(groupedOptions)?.map((options) =>
              options.map((option) => (
                <HighlightedOption
                  key={option.id}
                  option={option}
                  query={debouncedValue}
                  combobox={combobox}
                  selected={value?.includes(option.id)}
                />
              )),
            ),
      [cardTypes, combobox, groupedOptions, debouncedValue],
    );

    const rightSection = useMemo(() => {
      if (cardTypes.length > 1) {
        return (
          <Menu shadow="md" withArrow withinPortal={withinPortal}>
            <Menu.Target>
              <Button
                mx={2}
                size="xs"
                px={'xs'}
                radius={radius}
                variant="light"
                color="gray"
                rightSection={<IconChevronDown size={'0.8rem'} />}
                disabled={disabled}
              >
                {selectedCardType?.pluralName ?? t('All')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  setSelectedCardType(undefined);
                }}
                leftSection={<IconCards size={14} />}
              >
                {t('All')}
              </Menu.Item>
              <Menu.Label> {t('Cards Type')}</Menu.Label>
              {cardTypes.map((item) => (
                <Menu.Item
                  key={item.id}
                  leftSection={<IconCards size={14} />}
                  onClick={() => setSelectedCardType(item)}
                  {...testIdUtils.prop('card-type-selector')}
                >
                  {item.pluralName}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        );
      }
      return null;
    }, [cardTypes, radius, selectedCardType, t]);

    const leftSection = useMemo(
      () => (
        <Flex p={0} {...{ [align === 'right' ? 'mr' : 'ml']: 10 }}>
          {isFetching ? <Loader size="xs" /> : <IconSearch size="1rem" />}
        </Flex>
      ),
      [align, isFetching],
    );

    const placeHolder = useMemo(
      () => t('Search {{cardType}}', { cardType: selectedCardType?.pluralName ?? '' }),
      [selectedCardType?.pluralName, t],
    );
    return (
      <Combobox
        store={combobox}
        withinPortal={withinPortal}
        disabled={disabled}
        onOptionSubmit={(value: string) => {
          const card = cards?.find((item) => item.id === value);
          if (!card) return;
          const cardType = cardTypes.find((type) => type.id === card.cardTypeId);
          if (!cardType) return;
          onSubmit?.({ card, cardType });
          combobox.closeDropdown();
          setQuery('');
        }}
      >
        {value?.length ? (
          <Combobox.DropdownTarget>
            <PillsInput
              onClick={() => combobox.openDropdown()}
              leftSection={leftSection}
              radius={radius}
              styles={{ section: { width: 'auto' } }}
              rightSection={rightSection}
              label={label}
              description={description}
              disabled={disabled}
            >
              <Pill.Group>
                {values}
                <Combobox.EventsTarget>
                  <PillsInput.Field
                    ref={ref}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    value={query}
                    placeholder={placeHolder}
                    disabled={disabled}
                    onChange={(event) => {
                      combobox.openDropdown();
                      combobox.updateSelectedOptionIndex();
                      setQuery(event.currentTarget.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace' && query.length === 0) {
                        event.preventDefault();
                        const removed = value?.pop();
                        if (removed) onRemove?.(removed);
                      }
                    }}
                  />
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>
        ) : (
          <Combobox.Target>
            <TextInput
              name={name}
              value={query}
              disabled={disabled}
              onChange={(event) => {
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
                setQuery(event.currentTarget.value);
              }}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
              placeholder={placeHolder}
              leftSection={leftSection}
              radius={radius}
              styles={{ section: { width: 'auto' } }}
              rightSection={rightSection}
              label={label}
              description={description}
            />
          </Combobox.Target>
        )}
        {cards && cards.length > 0 && (
          <Combobox.Dropdown>
            <Combobox.Options>{groupedItems}</Combobox.Options>
          </Combobox.Dropdown>
        )}
        {cards && cards?.length === 0 && !isFetching && debouncedValue && (
          <Combobox.Dropdown>
            <Combobox.Empty>{t('No results found')}</Combobox.Empty>
          </Combobox.Dropdown>
        )}
      </Combobox>
    );
  },
);

GlobalSearchComponent.displayName = 'GlobalSearchComponent';
