import { Anchor, type AnchorProps } from '@mantine/core';
import NextLink, { type LinkProps } from 'next/link';

type AppLinkProps = AnchorProps & {
  href: string;
  newTab?: boolean;
  children: React.ReactNode;
};

export const AppLink = ({ href, children, c = 'blue', newTab = false, ...props }: AppLinkProps) => {
  return (
    <Anchor
      size="sm"
      href={href}
      c={c}
      component={NextLink}
      {...props}
      {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
    >
      {children}
    </Anchor>
  );
};

type ConditionLinkProps = LinkProps & {
  display: boolean;
  children: React.ReactNode;
};

export const ConditionLink: React.FC<ConditionLinkProps> = ({ display, children, ...props }) => {
  if (display) {
    return <NextLink {...props}>{children}</NextLink>;
  }
  return <>{children}</>;
};
