import {
  type DefaultMantineColor,
  type MantineColor,
  rgba,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { type Field, type FieldOption } from '~db/types';
import { appColors } from '../app-colors';

export const useMantineColor = (
  mantineColor: DefaultMantineColor = appColors.themeColor,
  lightShade = 6,
  darkShade?: number,
) => {
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();

  const color = useMemo(() => {
    if (colorScheme === 'dark') {
      return colors?.[mantineColor]?.[darkShade ?? 9 - lightShade];
    }
    return colors?.[mantineColor]?.[lightShade];
  }, [colorScheme, colors, mantineColor, lightShade, darkShade]);

  const get = useCallback(
    (color: DefaultMantineColor, shade = 6) => {
      if (colorScheme === 'dark') {
        return colors[color]![darkShade ?? 9 - shade]!;
      }
      return colors[color]![shade]!;
    },
    [colorScheme, colors, darkShade],
  );

  return { color, get };
};

const stringToHashNumber = (string: string) => {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return hash;
};

export const useLabelsColor = (
  labels: (string | null)[] = [],
  options?: Field['options'],
): FieldOption[] => {
  const { get } = useMantineColor();
  const labelsOption = useMemo<FieldOption[]>(() => {
    const remainingColors = [...appColors.mainColors];
    return labels.map((label) => {
      const option = options?.find((item) => item.value === label);
      if (option?.color) {
        return { ...option, color: rgba(get(option.color, 5), 0.7) };
      }
      if (remainingColors.length === 0) remainingColors.push(...appColors.mainColors);
      const color = remainingColors.splice(
        stringToHashNumber(label ?? '') % remainingColors.length,
        1,
      )[0]!;

      return { value: label ?? '', label: label ?? '', color: rgba(get(color), 1) };
    });
  }, [labels, options, get]);

  return labelsOption;
};

const splitColorAndShade = (mantineColor: MantineColor): { color: MantineColor; shade: number } => {
  const [color, shade] = mantineColor.split('.');
  return { color: color as MantineColor, shade: shade ? +shade : 6 };
};

export const useColorBySchema = (light: MantineColor, dark: MantineColor) => {
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();

  const color = useMemo(() => {
    const selected = colorScheme === 'dark' ? dark : light;
    const { color, shade } = splitColorAndShade(selected);
    return colors?.[color]?.[shade] ?? selected;
  }, [light, dark, colors, colorScheme]);

  return color;
};

export const useMantineColorToRgba = (
  mantineColor: MantineColor = appColors.themeColor,
  opacity = 1,
) => {
  const { colors } = useMantineTheme();
  const { color, shade } = splitColorAndShade(mantineColor);

  return rgba(colors?.[color]?.[shade] ?? mantineColor, opacity);
};
