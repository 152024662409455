import { currenciesMap } from '~/Components/Form/Currency/currencies';
import { type currencies } from '~db/schema/consts/general';

const format = (number: number): string => {
  if (number === 0) return '0';
  if (number < 0.00001)
    return new Intl.NumberFormat('en-US', {
      notation: 'scientific',
      maximumSignificantDigits: 3,
    }).format(+number);
  if (number < 100) {
    return new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 3,
    }).format(+number);
  }
  if (number < 1000) {
    return new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 4,
    }).format(+number);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(+number);
};

const isStringNumber = (number: string): boolean => {
  if (number === '') return false;
  return !Number.isNaN(+number);
};

const parseStringNumber = (number: string): number => +number;

const tryParse = (number: unknown, fallback?: number): number | undefined => {
  if (typeof number === 'number') return number;
  if (typeof number === 'string' && isStringNumber(number)) return parseStringNumber(number);
  if (typeof number === 'undefined') return fallback;
  if (number === null) return fallback;
  return fallback;
};

const tryFormat = (number: unknown): string => {
  if (typeof number === 'number') return format(number);
  const parsed = tryParse(number);
  if (typeof parsed === 'number') return format(parsed);
  return '';
};

const bytesToSize = (bytes: number): string =>
  Intl.NumberFormat(undefined, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  })
    .format(bytes)
    .replace('BB', 'GB');

const formatCurrency = (number: number, currency: (typeof currencies)[number] = 'ILS'): string => {
  if (number === 0) return '₪0';
  const locale = currency ? currenciesMap[currency]?.[0] : undefined;
  if (number < 100) {
    return new Intl.NumberFormat(locale, {
      maximumSignificantDigits: 3,
      style: 'currency',
      currency,
    }).format(number);
  }
  if (number < 1000) {
    return new Intl.NumberFormat(locale, {
      maximumSignificantDigits: 4,
      style: 'currency',
      currency,
    }).format(number);
  }

  if (number < 1_000_000) {
    return `${new Intl.NumberFormat(locale, {
      maximumFractionDigits: 1,
      style: 'currency',
      currency,
    }).format(number / 1000)}K`;
  }

  if (number < 1_000_000_000) {
    return `${new Intl.NumberFormat(locale, {
      maximumFractionDigits: 1,
      style: 'currency',
      currency,
    }).format(number / 1_000_000)}M`;
  }

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
    notation: 'compact',
    style: 'currency',
    currency,
  }).format(number);
};

const tryCurrency = (number: unknown, currency?: (typeof currencies)[number]): string => {
  if (typeof number === 'number') return formatCurrency(number, currency);
  const parsed = tryParse(number);
  if (typeof parsed === 'number') return formatCurrency(parsed, currency);
  return '';
};

const formatAccounting = (number = 0, currency: (typeof currencies)[number] = 'ILS'): string => {
  // two decimal places, and negative numbers in parentheses
  const locale = currency ? currenciesMap[currency]?.[0] : undefined;

  return new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const tryFormatAccounting = (number: unknown, currency: (typeof currencies)[number] = 'ILS'): string => {
  if (typeof number === 'number') return formatAccounting(number, currency);
  const parsed = tryParse(number);
  if (typeof parsed === 'number') return formatAccounting(parsed, currency);
  return '';
};

const round = (number: number, precision = 0) => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

export const numberService = {
  format,
  tryFormat,
  isStringNumber,
  tryParse,
  parseStringNumber,
  bytesToSize,
  currency: formatCurrency,
  tryCurrency,
  formatAccounting,
  tryFormatAccounting,
  round,
};
