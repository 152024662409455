import { Box, Button, Card, Grid, Stack, Tabs } from '@mantine/core';
import { DatePicker, type DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateComponentUtils } from '../date.component.utils';
import { useQuickSelectionOptions } from './display-date.hook';
import {
  type SuperDatePickerOnChange,
  type SuperDatePickerProps,
  type SuperDateRangeInitialValue,
  type SuperDateRangeValue,
} from './types';
import { superDateRangePickerUtils } from './utils';

const Absolute: React.FC<SuperDatePickerProps> = ({ onChange, setOpened, setValue, value }) => {
  const [range, setRange] = useState<DatesRangeValue>(
    value && value.length === 2 ? [value[0], value[1]] : [null, null],
  );

  return (
    <Stack align="center" justify="center" p="sm">
      <DatePicker
        renderDay={dateComponentUtils.dayRenderer}
        type="range"
        value={range}
        onChange={(range) => {
          setRange(range);
          const [fromDate, toDate] = range;
          if (fromDate && toDate) {
            const [from, to] = [
              dayjs(fromDate).startOf('day').toDate(),
              dayjs(toDate).endOf('day').toDate(),
            ];
            onChange([from, to]);
            setValue([from, to]);
            setOpened?.(false);
          }
        }}
        allowSingleDateInRange
      />
    </Stack>
  );
};

const QuickSelect: React.FC<SuperDatePickerProps> = ({ onChange, setOpened, setValue, value }) => {
  const quickSelectOptions = useQuickSelectionOptions();
  return (
    <Box p="sm">
      <Grid align="center" gutter={1}>
        {quickSelectOptions.map((option) => (
          <Grid.Col span={6} key={option.value}>
            <Button
              size="compact-xs"
              variant={value && value[0] === option.value ? 'light' : 'subtle'}
              onClick={() => {
                onChange([option.value]);
                setValue([option.value]);
                setOpened?.(false);
              }}
            >
              {option.label}
            </Button>
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};

interface ISuperDateRangePickerContainerProps {
  onChange: SuperDatePickerOnChange;
  initialValue?: SuperDateRangeInitialValue;
}

export const SuperDateRangePickerContainer = forwardRef<
  HTMLDivElement,
  ISuperDateRangePickerContainerProps
>(({ onChange, initialValue }, ref) => {
  const localRef = useRef(null);
  const inputRef = ref ?? localRef;
  const { t } = useTranslation('dateComponent');
  const parsedInitialValues = useMemo(
    () => superDateRangePickerUtils.tryParseInitialValue(initialValue),
    [],
  );
  const [selectedTab, setSelectedTab] = useState<string | null>(
    parsedInitialValues && parsedInitialValues.length === 2 ? 'calendar' : 'relative',
  );
  const [value, setValue] = useState<SuperDateRangeValue | undefined>(parsedInitialValues);

  return (
    <Card ref={inputRef} p={0} w="290px">
      <Tabs value={selectedTab} onChange={setSelectedTab}>
        <Tabs.List grow>
          <Tabs.Tab value="relative">{t('Relative')}</Tabs.Tab>
          <Tabs.Tab value="calendar">{t('Absolute')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="relative">
          <QuickSelect onChange={onChange} setValue={setValue} value={value} />
        </Tabs.Panel>
        <Tabs.Panel value="calendar">
          <Absolute onChange={onChange} setValue={setValue} value={value} />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
});

SuperDateRangePickerContainer.displayName = 'SuperDateRangePickerContainer';
