type BaseStopTypes = number | string | boolean;
type DefaultStopTypes = BaseStopTypes | BaseStopTypes[];
type PathsToFields<T, StopTypes = DefaultStopTypes> = T extends DefaultStopTypes
  ? ''
  : {
      [K in Extract<keyof T, string>]: Dot<K, PathsToFields<T[K], StopTypes>>;
    }[Extract<keyof T, string>];

type Dot<T extends string, U extends string> = '' extends U ? T : `${T}.${U}`;

type GetPropertyType<T, K extends string> = K extends keyof T
  ? T[K]
  : K extends `${infer Property}.${infer SubField}`
    ? Property extends keyof T
      ? GetPropertyType<NonNullable<T[Property]>, SubField>
      : never
    : never;

export const __utilsSet = <
  T extends Record<string, any>,
  P extends PathsToFields<T>,
  V extends GetPropertyType<T, P>,
>(
  obj: T,
  path: P,
  value: V,
): T => {
  const chunks = path.split('.');
  chunks.reduce<Record<string, any>>((acc, chunk, index) => {
    acc[chunk] ??= {};

    if (index === chunks.length - 1) acc[chunk] = value;

    return acc[chunk] as T;
  }, obj);

  return obj;
};
