import { Input, SegmentedControl, type SegmentedControlProps, Stack } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

export const FormRadio: React.FC<
  SegmentedControlProps &
    IBaseFormInputProps & {
      data: {
        label: string;
        value: string;
      }[];
    }
> = ({ name, label, required = false, data = [], onChange, ...props }) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange: fieldOnChange, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);
  return (
    <Input.Wrapper error={errorMessage}>
      {label ? (
        <Input.Label>
          <Label label={label} required={required} />
        </Input.Label>
      ) : null}
      <Stack gap={0}>
        <SegmentedControl
          value={value}
          data={data}
          onChange={(value) => {
            fieldOnChange(value);
            onChange?.(value);
          }}
          {...rest}
          {...props}
        />
      </Stack>
    </Input.Wrapper>
  );
};
